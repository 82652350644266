<template>
  <span>
    <span class="relative flex size-full items-center justify-center">
      <span
        class="absolute inline-flex size-full animate-ping-slow rounded-full bg-current opacity-75"
      />

      <span class="relative inline-flex size-full rounded-full bg-current" />
    </span>
  </span>
</template>
